<template>
  <div class="annx" v-loading="loading">
    <el-row style="margin-bottom: 15px" v-if="disabledNum != 1">
      <el-descriptions :column="4" border v-if="projectDisRuleOptions.projectInformation">
        <el-descriptions-item label="项目名称：" label-class-name="labelClassName" :span="3">
          {{ projectInformation.projectName }}
        </el-descriptions-item>
        <el-descriptions-item label="项目来源：" label-class-name="labelClassName">
          {{ projectDisRuleOptions.projectInformation.projectSource | dict(sourceType) }}
        </el-descriptions-item>
        <!-- <el-descriptions-item label="项目来源详细：">
          <div style="line-height:46px">
            {{
              projectDisRuleOptions.projectInformation.projectSourceDetailed
                | dict(sourceDetailedType)
            }}
          </div>
        </el-descriptions-item> -->
      </el-descriptions>
    </el-row>
    <el-row style="margin-bottom: 15px" v-if="disabledNum != 1">
      <el-col :span="19">
        <el-descriptions :column="8" border>
          <!-- <el-descriptions-item label="项目名称：">
            <span style="white-space: pre-wrap">{{ projectInformation.projectName }}</span>
          </el-descriptions-item> -->
          <el-descriptions-item label="项目金额：" label-class-name="labelClassName"
            ><el-input disabled v-model="projectInformation.actualAmount"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="实际税费：" label-class-name="labelClassName"
            ><el-input disabled v-model="projectInformation.actualTaxesFee"></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="实际招标费：" label-class-name="labelClassName"
            ><el-input disabled v-model="projectInformation.actualTenderFee"></el-input>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple-light flex" style="padding: 15px 0px 0px 20px">
          <el-button type="primary" plain @click="countFn">计算绩效</el-button>
          <el-button type="success" plain @click="changeFn">变更记录信息</el-button>
        </div></el-col
      >
    </el-row>

    <el-row>
      <el-row style="margin-bottom: 15px" v-if="disabledNum == 1">
        <el-col :span="12" style="font-weight: 900; color: #000; font-size: 20px">变更前</el-col>
        <el-col :span="12" style="font-weight: 900; color: rgb(245, 62, 62); font-size: 20px"
          >变更后</el-col
        >
      </el-row>
      <template v-if="disabledNum == 1">
        <el-col :span="12"
          ><div class="grid-content bg-purple-light">
            <el-card>
              <div slot="header">
                <span
                  style="font-weight: 900; color: black"
                  v-if="projectDisRuleOptions.projectType == 'chan_pin_xiao_shou_lei_xiang_mu'"
                  >{{
                    projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                  }}</span
                >
                <span
                  style="font-weight: 900; color: black"
                  v-if="
                    projectDisRuleOptions.projectType ==
                    'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'
                  "
                  >{{
                    projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                  }}</span
                >
                <span
                  style="font-weight: 900; color: black"
                  v-if="
                    projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'
                  "
                  >{{
                    projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                  }}</span
                >
              </div>

              <el-form class="column4">
                <!-- <el-form class="column2" label-width="13em"> -->

                <!-- <el-descriptions :column="4" border> -->
                <!-- <el-descriptions-item label=""> -->
                <!-- <el-form-item
                  label="公司公共费(元)"
                  prop="companyBasicChargeDis"
                  ref="companyBasicChargeDis"
                  style="width:24%;display: inline-block;"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="
                      projectPerformanceDataResponse.companyBasicChargeDis
                    "
                  ></el-input>
                </el-form-item> -->
                <!-- </el-descriptions-item> -->
                <!-- 定额类 -->
                <template
                  v-if="
                    projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'
                  "
                >
                  <div class="title_text bgc4">公共费/人员成本/报销类</div>
                  <el-form-item
                    label="公司公共费(元)"
                    prop="companyBasicChargeDis"
                    ref="companyBasicChargeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.companyBasicChargeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="员工的生产成本(元)"
                    prop="staffProductionCostDis"
                    ref="staffProductionCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.staffProductionCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目总报销(元)"
                    prop="projectExpenseSumDis"
                    ref="projectExpenseSumDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.projectExpenseSumDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目可支配费用(元)"
                    prop="actualDisposableProjectExpenseDis"
                    ref="actualDisposableProjectExpenseDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.actualDisposableProjectExpenseDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc3">商务类</div>
                  <el-form-item
                    label="总商务绩效(元)"
                    prop="sumBusinessPerformanceDis"
                    ref="sumBusinessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.sumBusinessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="基本商务费(元)"
                    prop="basicBusinessFeeDis"
                    ref="basicBusinessFeeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.basicBusinessFeeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="基本商务绩效(元)"
                    prop="basicBusinessPerformanceDis"
                    ref="basicBusinessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.basicBusinessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="商务部运作费用(元)"
                    prop="businessOperationFeeDis"
                    ref="businessOperationFeeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.businessOperationFeeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->

                  <div class="title_text bgc2">成本/绩效/利润类</div>
                  <el-form-item
                    label="生产定额成本(元)"
                    prop="productionQuotaCostDis"
                    ref="productionQuotaCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productionQuotaCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="生产实施直接成本(元)"
                    prop="productionActualCostDis"
                    ref="productionActualCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productionActualCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="生产绩效(元)"
                    prop="productionPerformanceDis"
                    ref="productionPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productionPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目利润(元)"
                    prop="projectPerformanceDis"
                    ref="projectPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.projectPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="公司利润(元)"
                    prop="corporateProfitDis"
                    ref="corporateProfitDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.corporateProfitDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="利润商务绩效(元)"
                    prop="businessPerformanceDis"
                    ref="businessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.businessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc1">部门/外协类</div>
                  <el-form-item
                    label="部门管理总绩效(元)"
                    prop="implementationManagementPerformanceDis"
                    ref="implementationManagementPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="
                        projectPerformanceDataResponse.implementationManagementPerformanceDis
                      "
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->

                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="外协工作监督绩效(元)"
                    prop="outSourceSupervisionPerformanceDis"
                    ref="outSourceSupervisionPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.outSourceSupervisionPerformanceDis"
                    ></el-input>
                    <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="外协费用(元)"
                    prop="outsourceCostDis"
                    ref="outsourceCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.outsourceCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                </template>
                <!-- 实算类 -->
                <template
                  v-if="
                    projectDisRuleOptions.projectType ==
                    'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'
                  "
                >
                  <div class="title_text bgc4">公共费/人员成本/报销类</div>
                  <el-form-item
                    label="公司公共费(元)"
                    prop="companyBasicChargeDis"
                    ref="companyBasicChargeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.companyBasicChargeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="员工的生产成本(元)"
                    prop="staffProductionCostDis"
                    ref="staffProductionCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.staffProductionCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目总报销(元)"
                    prop="projectExpenseSumDis"
                    ref="projectExpenseSumDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.projectExpenseSumDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目可支配费用(元)"
                    prop="actualDisposableProjectExpenseDis"
                    ref="actualDisposableProjectExpenseDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.actualDisposableProjectExpenseDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc3">商务类</div>
                  <el-form-item
                    label="总商务绩效(元)"
                    prop="sumBusinessPerformanceDis"
                    ref="sumBusinessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.sumBusinessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="基本商务费(元)"
                    prop="basicBusinessFeeDis"
                    ref="basicBusinessFeeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.basicBusinessFeeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="基本商务绩效(元)"
                    prop="basicBusinessPerformanceDis"
                    ref="basicBusinessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.basicBusinessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="商务部运作费用(元)"
                    prop="businessOperationFeeDis"
                    ref="businessOperationFeeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.businessOperationFeeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc2">成本/绩效/利润类</div>
                  <el-form-item
                    label="生产成本(元)"
                    prop="productionActualCostDis"
                    ref="productionActualCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productionActualCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="生产绩效(元)"
                    prop="productionPerformanceDis"
                    ref="productionPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productionPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="利润商务绩效(元)"
                    prop="businessPerformanceDis"
                    ref="businessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.businessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目利润(元)"
                    prop="projectPerformanceDis"
                    ref="projectPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.projectPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="公司利润(元)"
                    prop="corporateProfitDis"
                    ref="corporateProfitDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.corporateProfitDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc1">部门/外协类</div>
                  <el-form-item
                    label="部门管理总绩效(元)"
                    prop="implementationManagementPerformanceDis"
                    ref="implementationManagementPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="
                        projectPerformanceDataResponse.implementationManagementPerformanceDis
                      "
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="外协工作监督绩效(元)"
                    prop="outSourceSupervisionPerformanceDis"
                    ref="outSourceSupervisionPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.outSourceSupervisionPerformanceDis"
                    ></el-input>
                    <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="外协费用(元)"
                    prop="outsourceCostDis"
                    ref="outsourceCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.outsourceCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                </template>
                <!-- 销售类 -->
                <template
                  v-if="projectDisRuleOptions.projectType == 'chan_pin_xiao_shou_lei_xiang_mu'"
                >
                  <div class="title_text bgc4">公共费/人员成本/报销类</div>
                  <el-form-item
                    label="公司公共费(元)"
                    prop="companyBasicChargeDis"
                    ref="companyBasicChargeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.companyBasicChargeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="定制改造人工成本(元)"
                    prop="costUpgradingDis"
                    ref="costUpgradingDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.costUpgradingDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目总报销(元)"
                    prop="projectExpenseSumDis"
                    ref="projectExpenseSumDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.projectExpenseSumDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目可支配费用(元)"
                    prop="actualDisposableProjectExpenseDis"
                    ref="actualDisposableProjectExpenseDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.actualDisposableProjectExpenseDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc3">商务类</div>
                  <el-form-item
                    label="总商务绩效(元)"
                    prop="sumBusinessPerformanceDis"
                    ref="sumBusinessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.sumBusinessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="基本商务费(元)"
                    prop="basicBusinessFeeDis"
                    ref="basicBusinessFeeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.basicBusinessFeeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="基本商务绩效(元)"
                    prop="basicBusinessPerformanceDis"
                    ref="basicBusinessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.basicBusinessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="商务部运作费用(元)"
                    prop="businessOperationFeeDis"
                    ref="businessOperationFeeDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.businessOperationFeeDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc2">成本/绩效/利润类</div>
                  <el-form-item
                    label="生产成本(元)"
                    prop="productionActualCostDis"
                    ref="productionActualCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productionActualCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="部署维护人工成本(元)"
                    prop="deploymentMaintenanceCostDis"
                    ref="deploymentMaintenanceCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.deploymentMaintenanceCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="项目利润(元)"
                    prop="projectPerformanceDis"
                    ref="projectPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.projectPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="利润商务绩效(元)"
                    prop="businessPerformanceDis"
                    ref="businessPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.businessPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="生产绩效(元)"
                    prop="productionPerformanceDis"
                    ref="productionPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productionPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="产品绩效(元)"
                    prop="productPerformanceDis"
                    ref="productPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.productPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="定制改造生产绩效(元)"
                    prop="costUpgradingPerformanceDis"
                    ref="costUpgradingPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.costUpgradingPerformanceDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="部署维护生产绩效(元)"
                    prop="deploymentMaintenanceCostPerformanceDis"
                    ref="deploymentMaintenanceCostPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="
                        projectPerformanceDataResponse.deploymentMaintenanceCostPerformanceDis
                      "
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="公司利润(元)"
                    prop="corporateProfitDis"
                    ref="corporateProfitDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.corporateProfitDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <div class="title_text bgc1">部门/外协类</div>
                  <el-form-item
                    label="部门管理总绩效(元)"
                    prop="implementationManagementPerformanceDis"
                    ref="implementationManagementPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="
                        projectPerformanceDataResponse.implementationManagementPerformanceDis
                      "
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="外协工作监督绩效(元)"
                    prop="outSourceSupervisionPerformanceDis"
                    ref="outSourceSupervisionPerformanceDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.outSourceSupervisionPerformanceDis"
                    ></el-input>
                    <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                  <!-- <el-descriptions-item label=""> -->
                  <el-form-item
                    label="外协费用(元)"
                    prop="outsourceCostDis"
                    ref="outsourceCostDis"
                    style="width: 24%; display: inline-block"
                  >
                    <el-input
                      disabled
                      class="bd_input"
                      v-model="projectPerformanceDataResponse.outsourceCostDis"
                    ></el-input>
                  </el-form-item>
                  <!-- </el-descriptions-item> -->
                </template>
                <!-- </el-descriptions> -->
              </el-form>
            </el-card>
          </div></el-col
        >
      </template>
      <el-col :span="12" v-else
        ><div class="grid-content bg-purple">
          <el-card>
            <div slot="header">
              <span
                style="font-weight: 900; color: black"
                v-if="projectDisRuleOptions.projectType == 'chan_pin_xiao_shou_lei_xiang_mu'"
                >{{
                  projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                }}</span
              >
              <span
                style="font-weight: 900; color: black"
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'
                "
                >{{
                  projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                }}</span
              >
              <span
                style="font-weight: 900; color: black"
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'
                "
                >{{
                  projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                }}</span
              >
            </div>
            <el-form>
              <!-- <el-descriptions :column="4" border> -->
              <!-- <el-descriptions-item label=""> -->
              <!-- <el-form-item class="column4"
                label="公司公共费(元)"
                prop="companyBasicCharge"
                ref="companyBasicCharge"
                
                  style="width:24%;display: inline-block;"
              >
                <el-input
                  class="bd_input"
                  disabled
                  v-model="projectPerformanceDataResponse.companyBasicCharge"
                ></el-input>
              </el-form-item> -->
              <!-- </el-descriptions-item> -->

              <!-- 定额类 -->
              <template
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'
                "
              >
                <div class="title_text bgc4">公共费/人员成本/报销类</div>
                <el-form-item
                  label="公司公共费(元)"
                  prop="companyBasicCharge"
                  ref="companyBasicCharge"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    class="bd_input"
                    disabled
                    v-model="projectPerformanceDataResponse.companyBasicCharge"
                  ></el-input>
                </el-form-item>
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="员工的生产成本(元)"
                  prop="staffProductionCost"
                  ref="staffProductionCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.staffProductionCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目总报销(元)"
                  prop="projectExpenseSum"
                  ref="projectExpenseSum"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.projectExpenseSum"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目可支配费用(元)"
                  prop="actualDisposableProjectExpense"
                  ref="actualDisposableProjectExpense"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.actualDisposableProjectExpense"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc3">商务类</div>
                <el-form-item
                  label="总商务绩效(元)"
                  prop="sumBusinessPerformance"
                  ref="sumBusinessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.sumBusinessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务费(元)"
                  prop="basicBusinessFee"
                  ref="basicBusinessFee"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    class="bd_input"
                    disabled
                    v-model="projectPerformanceDataResponse.basicBusinessFee"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务绩效(元)"
                  prop="basicBusinessPerformance"
                  ref="basicBusinessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.basicBusinessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="商务部运作费用(元)"
                  prop="businessOperationFee"
                  ref="businessOperationFee"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    class="bd_input"
                    disabled
                    v-model="projectPerformanceDataResponse.businessOperationFee"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc2">成本/绩效类</div>
                <el-form-item
                  label="生产定额成本(元)"
                  prop="productionQuotaCost"
                  ref="productionQuotaCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productionQuotaCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-form-item
                  label="直接成本(元)"
                  prop="productionActualCostDis"
                  ref="productionActualCostDis"
                  style="width:24%;display: inline-block;"
                >
                  <el-input
                  disabled
                  
                class="bd_input"
                    v-model="projectPerformanceDataResponse.productionActualCostDis"
                  ></el-input>
                </el-form-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产实施直接成本(元)"
                  prop="productionActualCost"
                  ref="productionActualCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productionActualCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产绩效(元)"
                  prop="productionPerformance"
                  ref="productionPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productionPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目利润(元)"
                  prop="projectPerformance"
                  ref="projectPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.projectPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="公司利润(元)"
                  prop="corporateProfit"
                  ref="corporateProfit"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.corporateProfit"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="利润商务绩效(元)"
                  prop="businessPerformance"
                  ref="businessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.businessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->

                <div class="title_text bgc1">部门/外协类</div>
                <el-form-item
                  label="部门管理总绩效(元)"
                  prop="implementationManagementPerformance"
                  ref="implementationManagementPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.implementationManagementPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协工作监督绩效(元)"
                  prop="outSourceSupervisionPerformance"
                  ref="outSourceSupervisionPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.outSourceSupervisionPerformance"
                  ></el-input>
                  <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协费用(元)"
                  prop="outsourceCost"
                  ref="outsourceCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.outsourceCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
              </template>
              <!-- 实算类 -->
              <template
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'
                "
              >
                <div class="title_text bgc4">公共费/人员成本/报销类</div>
                <el-form-item
                  label="公司公共费(元)"
                  prop="companyBasicCharge"
                  ref="companyBasicCharge"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    class="bd_input"
                    disabled
                    v-model="projectPerformanceDataResponse.companyBasicCharge"
                  ></el-input>
                </el-form-item>
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="员工的生产成本(元)"
                  prop="staffProductionCost"
                  ref="staffProductionCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.staffProductionCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目总报销(元)"
                  prop="projectExpenseSum"
                  ref="projectExpenseSum"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.projectExpenseSum"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目可支配费用(元)"
                  prop="actualDisposableProjectExpense"
                  ref="actualDisposableProjectExpense"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.actualDisposableProjectExpense"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc3">商务类</div>
                <el-form-item
                  label="总商务绩效(元)"
                  prop="sumBusinessPerformance"
                  ref="sumBusinessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.sumBusinessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务费(元)"
                  prop="basicBusinessFee"
                  ref="basicBusinessFee"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.basicBusinessFee"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务绩效(元)"
                  prop="basicBusinessPerformance"
                  ref="basicBusinessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.basicBusinessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="商务部运作费用(元)"
                  prop="businessOperationFee"
                  ref="businessOperationFee"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.businessOperationFee"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->

                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc2">成本/绩效/利润类</div>
                <el-form-item
                  label="生产成本(元)"
                  prop="productionActualCost"
                  ref="productionActualCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productionActualCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->

                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产绩效(元)"
                  prop="productionPerformance"
                  ref="productionPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productionPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="利润商务绩效(元)"
                  prop="businessPerformance"
                  ref="businessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.businessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目利润(元)"
                  prop="projectPerformance"
                  ref="projectPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.projectPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="公司利润(元)"
                  prop="corporateProfit"
                  ref="corporateProfit"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.corporateProfit"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc1">部门/外协类</div>
                <el-form-item
                  label="部门管理总绩效(元)"
                  prop="implementationManagementPerformance"
                  style="width: 24%; display: inline-block"
                  ref="implementationManagementPerformance"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.implementationManagementPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协工作监督绩效(元)"
                  prop="outSourceSupervisionPerformance"
                  style="width: 24%; display: inline-block"
                  ref="outSourceSupervisionPerformance"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.outSourceSupervisionPerformance"
                  ></el-input>
                  <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协费用(元)"
                  prop="outsourceCost"
                  ref="outsourceCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.outsourceCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
              </template>
              <!-- 销售类 -->
              <template
                v-if="projectDisRuleOptions.projectType == 'chan_pin_xiao_shou_lei_xiang_mu'"
              >
                <div class="title_text bgc4">公共费/人员成本/报销类</div>
                <el-form-item
                  label="公司公共费(元)"
                  prop="companyBasicCharge"
                  ref="companyBasicCharge"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    class="bd_input"
                    disabled
                    v-model="projectPerformanceDataResponse.companyBasicCharge"
                  ></el-input>
                </el-form-item>
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="定制改造人工成本(元)"
                  prop="costUpgrading"
                  ref="costUpgrading"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.costUpgrading"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目总报销(元)"
                  prop="projectExpenseSum"
                  ref="projectExpenseSum"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.projectExpenseSum"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目可支配费用(元)"
                  prop="actualDisposableProjectExpense"
                  ref="actualDisposableProjectExpense"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.actualDisposableProjectExpense"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc3">商务类</div>
                <el-form-item
                  label="总商务绩效(元)"
                  prop="sumBusinessPerformance"
                  ref="sumBusinessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.sumBusinessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务费(元)"
                  prop="basicBusinessFee"
                  ref="basicBusinessFee"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.basicBusinessFee"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务绩效(元)"
                  prop="basicBusinessPerformance"
                  ref="basicBusinessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.basicBusinessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="商务部运作费用(元)"
                  prop="businessOperationFee"
                  ref="businessOperationFee"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.businessOperationFee"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->

                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产成本(元)"
                  prop="productionActualCost"
                  ref="productionActualCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productionActualCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="部署维护人工成本(元)"
                  prop="deploymentMaintenanceCost"
                  ref="deploymentMaintenanceCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.deploymentMaintenanceCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目利润(元)"
                  prop="projectPerformance"
                  ref="projectPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.projectPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="利润商务绩效(元)"
                  prop="businessPerformance"
                  ref="businessPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.businessPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc2">成本/绩效/利润类</div>
                <el-form-item
                  label="生产绩效(元)"
                  prop="productionPerformance"
                  ref="productionPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productionPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="产品绩效(元)"
                  prop="productPerformance"
                  ref="productPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.productPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->

                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="定制改造生产绩效(元)"
                  prop="costUpgradingPerformance"
                  ref="costUpgradingPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.costUpgradingPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="部署维护生产绩效(元)"
                  prop="deploymentMaintenanceCostPerformance"
                  ref="deploymentMaintenanceCostPerformance"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.deploymentMaintenanceCostPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="公司利润(元)"
                  prop="corporateProfit"
                  ref="corporateProfit"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.corporateProfit"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc1">部门/外协类</div>
                <el-form-item
                  label="部门管理总绩效(元)"
                  prop="implementationManagementPerformance"
                  style="width: 24%; display: inline-block"
                  ref="implementationManagementPerformance"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.implementationManagementPerformance"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协工作监督绩效(元)"
                  prop="outSourceSupervisionPerformance"
                  style="width: 24%; display: inline-block"
                  ref="outSourceSupervisionPerformance"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.outSourceSupervisionPerformance"
                  ></el-input>
                  <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协费用(元)"
                  prop="outsourceCost"
                  ref="outsourceCost"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    disabled
                    class="bd_input"
                    v-model="projectPerformanceDataResponse.outsourceCost"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
              </template>
              <!-- </el-descriptions > -->
            </el-form>
          </el-card>
        </div></el-col
      >

      <el-col :span="12"
        ><div class="grid-content bg-purple-light">
          <el-card>
            <div slot="header">
              <span
                style="font-weight: 900; color: black"
                v-if="projectDisRuleOptions.projectType == 'chan_pin_xiao_shou_lei_xiang_mu'"
                >{{
                  projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                }}</span
              >
              <span
                style="font-weight: 900; color: black"
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'
                "
                >{{
                  projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                }}</span
              >
              <span
                style="font-weight: 900; color: black"
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'
                "
                >{{
                  projectDisRuleOptions.projectType | dict(DisposableType, 'dictVal', 'dictName')
                }}</span
              >
            </div>
            <!-- 可编辑 -->
            <el-form class="column4">
              <!-- <el-form class="column2" label-width="13em"> -->

              <!-- <el-descriptions :column="4" border> -->
              <!-- <el-descriptions-item label=""> -->
              <!-- <el-form-item
                label="公司公共费(元)"
                prop="companyBasicChargeDis"
                ref="companyBasicChargeDis"
                  style="width:24%;display: inline-block;"
              >
                <el-input
                  :disabled="disabledNum == 1 || disabled"
                  :class="
                    projectPerformanceIsAlreadyChangeRequest.companyBasicChargeDis ==
                    1
                      ? 'bd_input color'
                      : 'bd_input'
                  "
                  @input="inputAmount('companyBasicChargeDis')"
                  @focus="focusAmount('companyBasicChargeDis')"
                  @blur="blurAmount('companyBasicChargeDis')"
                  v-model="
                    projectPerformanceDisDataResponse.companyBasicChargeDis
                  "
                ></el-input>
              </el-form-item> -->
              <!-- </el-descriptions-item> -->
              <!-- 定额类 -->
              <template
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_ding_e_lei_xiang_mu'
                "
              >
                <div class="title_text bgc4">公共费/人员成本/报销类</div>
                <el-form-item
                  label="公司公共费(元)"
                  prop="companyBasicChargeDis"
                  ref="companyBasicChargeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.companyBasicChargeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('companyBasicChargeDis')"
                    @focus="focusAmount('companyBasicChargeDis')"
                    @blur="blurAmount('companyBasicChargeDis')"
                    v-model="projectPerformanceDisDataResponse.companyBasicChargeDis"
                  ></el-input>
                </el-form-item>
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="员工的生产成本(元)"
                  prop="staffProductionCostDis"
                  ref="staffProductionCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.staffProductionCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('staffProductionCostDis')"
                    @focus="focusAmount('staffProductionCostDis')"
                    @blur="blurAmount('staffProductionCostDis')"
                    v-model="projectPerformanceDisDataResponse.staffProductionCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目总报销(元)"
                  prop="projectExpenseSumDis"
                  ref="projectExpenseSumDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.projectExpenseSumDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('projectExpenseSumDis')"
                    @focus="focusAmount('projectExpenseSumDis')"
                    @blur="blurAmount('projectExpenseSumDis')"
                    v-model="projectPerformanceDisDataResponse.projectExpenseSumDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目可支配费用(元)"
                  prop="actualDisposableProjectExpenseDis"
                  ref="actualDisposableProjectExpenseDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.actualDisposableProjectExpenseDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('actualDisposableProjectExpenseDis')"
                    @focus="focusAmount('actualDisposableProjectExpenseDis')"
                    @blur="blurAmount('actualDisposableProjectExpenseDis')"
                    v-model="projectPerformanceDisDataResponse.actualDisposableProjectExpenseDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc3">商务类</div>
                <el-form-item
                  label="总商务绩效(元)"
                  prop="sumBusinessPerformanceDis"
                  ref="sumBusinessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.sumBusinessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('sumBusinessPerformanceDis')"
                    @focus="focusAmount('sumBusinessPerformanceDis')"
                    @blur="blurAmount('sumBusinessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.sumBusinessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务费(元)"
                  prop="basicBusinessFeeDis"
                  ref="basicBusinessFeeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.basicBusinessFeeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('basicBusinessFeeDis')"
                    @focus="focusAmount('basicBusinessFeeDis')"
                    @blur="blurAmount('basicBusinessFeeDis')"
                    v-model="projectPerformanceDisDataResponse.basicBusinessFeeDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务绩效(元)"
                  prop="basicBusinessPerformanceDis"
                  ref="basicBusinessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.basicBusinessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('basicBusinessPerformanceDis')"
                    @focus="focusAmount('basicBusinessPerformanceDis')"
                    @blur="blurAmount('basicBusinessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.basicBusinessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="商务部运作费用(元)"
                  prop="businessOperationFeeDis"
                  ref="businessOperationFeeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.businessOperationFeeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('businessOperationFeeDis')"
                    @focus="focusAmount('businessOperationFeeDis')"
                    @blur="blurAmount('businessOperationFeeDis')"
                    v-model="projectPerformanceDisDataResponse.businessOperationFeeDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc2">成本/绩效类</div>
                <el-form-item
                  label="生产定额成本(元)"
                  prop="productionQuotaCostDis"
                  ref="productionQuotaCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productionQuotaCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productionQuotaCostDis')"
                    @focus="focusAmount('productionQuotaCostDis')"
                    @blur="blurAmount('productionQuotaCostDis')"
                    v-model="projectPerformanceDisDataResponse.productionQuotaCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产实施直接成本(元)"
                  prop="productionActualCostDis"
                  ref="productionActualCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productionActualCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productionActualCostDis')"
                    @focus="focusAmount('productionActualCostDis')"
                    @blur="blurAmount('productionActualCostDis')"
                    v-model="projectPerformanceDisDataResponse.productionActualCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产绩效(元)"
                  prop="productionPerformanceDis"
                  ref="productionPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productionPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productionPerformanceDis')"
                    @focus="focusAmount('productionPerformanceDis')"
                    @blur="blurAmount('productionPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.productionPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目利润(元)"
                  prop="projectPerformanceDis"
                  ref="projectPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.projectPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('projectPerformanceDis')"
                    @focus="focusAmount('projectPerformanceDis')"
                    @blur="blurAmount('projectPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.projectPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="公司利润(元)"
                  prop="corporateProfitDis"
                  ref="corporateProfitDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.corporateProfitDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('corporateProfitDis')"
                    @focus="focusAmount('corporateProfitDis')"
                    @blur="blurAmount('corporateProfitDis')"
                    v-model="projectPerformanceDisDataResponse.corporateProfitDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="利润商务绩效(元)"
                  prop="businessPerformanceDis"
                  ref="businessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.businessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('businessPerformanceDis')"
                    @focus="focusAmount('businessPerformanceDis')"
                    @blur="blurAmount('businessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.businessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc1">部门/外协类</div>
                <el-form-item
                  label="部门管理总绩效(元)"
                  prop="implementationManagementPerformanceDis"
                  ref="implementationManagementPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.implementationManagementPerformanceDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('implementationManagementPerformanceDis')"
                    @focus="focusAmount('implementationManagementPerformanceDis')"
                    @blur="blurAmount('implementationManagementPerformanceDis')"
                    v-model="
                      projectPerformanceDisDataResponse.implementationManagementPerformanceDis
                    "
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协工作监督绩效(元)"
                  prop="outSourceSupervisionPerformanceDis"
                  ref="outSourceSupervisionPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.outSourceSupervisionPerformanceDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('outSourceSupervisionPerformanceDis')"
                    @focus="focusAmount('outSourceSupervisionPerformanceDis')"
                    @blur="blurAmount('outSourceSupervisionPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.outSourceSupervisionPerformanceDis"
                  ></el-input>
                  <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协费用(元)"
                  prop="outsourceCostDis"
                  ref="outsourceCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.outsourceCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('outsourceCostDis')"
                    @focus="focusAmount('outsourceCostDis')"
                    @blur="blurAmount('outsourceCostDis')"
                    v-model="projectPerformanceDisDataResponse.outsourceCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
              </template>
              <!-- 实算类 -->
              <template
                v-if="
                  projectDisRuleOptions.projectType == 'sheng_chan_cheng_ben_shi_suan_lei_xiang_mu'
                "
              >
                <div class="title_text bgc4">公共费/人员成本/报销类</div>
                <el-form-item
                  label="公司公共费(元)"
                  prop="companyBasicChargeDis"
                  ref="companyBasicChargeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.companyBasicChargeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('companyBasicChargeDis')"
                    @focus="focusAmount('companyBasicChargeDis')"
                    @blur="blurAmount('companyBasicChargeDis')"
                    v-model="projectPerformanceDisDataResponse.companyBasicChargeDis"
                  ></el-input>
                </el-form-item>
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="员工的生产成本(元)"
                  prop="staffProductionCostDis"
                  ref="staffProductionCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.staffProductionCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('staffProductionCostDis')"
                    @focus="focusAmount('staffProductionCostDis')"
                    @blur="blurAmount('staffProductionCostDis')"
                    v-model="projectPerformanceDisDataResponse.staffProductionCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目总报销(元)"
                  prop="projectExpenseSumDis"
                  ref="projectExpenseSumDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.projectExpenseSumDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('projectExpenseSumDis')"
                    @focus="focusAmount('projectExpenseSumDis')"
                    @blur="blurAmount('projectExpenseSumDis')"
                    v-model="projectPerformanceDisDataResponse.projectExpenseSumDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目可支配费用(元)"
                  prop="actualDisposableProjectExpenseDis"
                  ref="actualDisposableProjectExpenseDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.actualDisposableProjectExpenseDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('actualDisposableProjectExpenseDis')"
                    @focus="focusAmount('actualDisposableProjectExpenseDis')"
                    @blur="blurAmount('actualDisposableProjectExpenseDis')"
                    v-model="projectPerformanceDisDataResponse.actualDisposableProjectExpenseDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc3">商务类</div>
                <el-form-item
                  label="总商务绩效(元)"
                  prop="sumBusinessPerformanceDis"
                  ref="sumBusinessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.sumBusinessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('sumBusinessPerformanceDis')"
                    @focus="focusAmount('sumBusinessPerformanceDis')"
                    @blur="blurAmount('sumBusinessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.sumBusinessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务费(元)"
                  prop="basicBusinessFeeDis"
                  ref="basicBusinessFeeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.basicBusinessFeeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('basicBusinessFeeDis')"
                    @focus="focusAmount('basicBusinessFeeDis')"
                    @blur="blurAmount('basicBusinessFeeDis')"
                    v-model="projectPerformanceDisDataResponse.basicBusinessFeeDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务绩效(元)"
                  prop="basicBusinessPerformanceDis"
                  ref="basicBusinessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.basicBusinessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('basicBusinessPerformanceDis')"
                    @focus="focusAmount('basicBusinessPerformanceDis')"
                    @blur="blurAmount('basicBusinessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.basicBusinessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="商务部运作费用(元)"
                  prop="businessOperationFeeDis"
                  ref="businessOperationFeeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.businessOperationFeeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('businessOperationFeeDis')"
                    @focus="focusAmount('businessOperationFeeDis')"
                    @blur="blurAmount('businessOperationFeeDis')"
                    v-model="projectPerformanceDisDataResponse.businessOperationFeeDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc2">成本/绩效/利润类</div>
                <el-form-item
                  label="生产成本(元)"
                  prop="productionActualCostDis"
                  ref="productionActualCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productionActualCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productionActualCostDis')"
                    @focus="focusAmount('productionActualCostDis')"
                    @blur="blurAmount('productionActualCostDis')"
                    v-model="projectPerformanceDisDataResponse.productionActualCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产绩效(元)"
                  prop="productionPerformanceDis"
                  ref="productionPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productionPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productionPerformanceDis')"
                    @focus="focusAmount('productionPerformanceDis')"
                    @blur="blurAmount('productionPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.productionPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="利润商务绩效(元)"
                  prop="businessPerformanceDis"
                  ref="businessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.businessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('businessPerformanceDis')"
                    @focus="focusAmount('businessPerformanceDis')"
                    @blur="blurAmount('businessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.businessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目利润(元)"
                  prop="projectPerformanceDis"
                  ref="projectPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.projectPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('projectPerformanceDis')"
                    @focus="focusAmount('projectPerformanceDis')"
                    @blur="blurAmount('projectPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.projectPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="公司利润(元)"
                  prop="corporateProfitDis"
                  ref="corporateProfitDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.corporateProfitDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('corporateProfitDis')"
                    @focus="focusAmount('corporateProfitDis')"
                    @blur="blurAmount('corporateProfitDis')"
                    v-model="projectPerformanceDisDataResponse.corporateProfitDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc1">部门/外协类</div>
                <el-form-item
                  label="部门管理总绩效(元)"
                  prop="implementationManagementPerformanceDis"
                  ref="implementationManagementPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.implementationManagementPerformanceDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('implementationManagementPerformanceDis')"
                    @focus="focusAmount('implementationManagementPerformanceDis')"
                    @blur="blurAmount('implementationManagementPerformanceDis')"
                    v-model="
                      projectPerformanceDisDataResponse.implementationManagementPerformanceDis
                    "
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协工作监督绩效(元)"
                  prop="outSourceSupervisionPerformanceDis"
                  ref="outSourceSupervisionPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.outSourceSupervisionPerformanceDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('outSourceSupervisionPerformanceDis')"
                    @focus="focusAmount('outSourceSupervisionPerformanceDis')"
                    @blur="blurAmount('outSourceSupervisionPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.outSourceSupervisionPerformanceDis"
                  ></el-input>
                  <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协费用(元)"
                  prop="outsourceCostDis"
                  ref="outsourceCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.outsourceCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('outsourceCostDis')"
                    @focus="focusAmount('outsourceCostDis')"
                    @blur="blurAmount('outsourceCostDis')"
                    v-model="projectPerformanceDisDataResponse.outsourceCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
              </template>
              <!-- 销售类 -->
              <template
                v-if="projectDisRuleOptions.projectType == 'chan_pin_xiao_shou_lei_xiang_mu'"
              >
                <div class="title_text bgc4">公共费/人员成本/报销类</div>
                <el-form-item
                  label="公司公共费(元)"
                  prop="companyBasicChargeDis"
                  ref="companyBasicChargeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.companyBasicChargeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('companyBasicChargeDis')"
                    @focus="focusAmount('companyBasicChargeDis')"
                    @blur="blurAmount('companyBasicChargeDis')"
                    v-model="projectPerformanceDisDataResponse.companyBasicChargeDis"
                  ></el-input>
                </el-form-item>
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="定制改造人工成本(元)"
                  prop="costUpgradingDis"
                  ref="costUpgradingDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.costUpgradingDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('costUpgradingDis')"
                    @focus="focusAmount('costUpgradingDis')"
                    @blur="blurAmount('costUpgradingDis')"
                    v-model="projectPerformanceDisDataResponse.costUpgradingDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目总报销(元)"
                  prop="projectExpenseSumDis"
                  ref="projectExpenseSumDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.projectExpenseSumDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('projectExpenseSumDis')"
                    @focus="focusAmount('projectExpenseSumDis')"
                    @blur="blurAmount('projectExpenseSumDis')"
                    v-model="projectPerformanceDisDataResponse.projectExpenseSumDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目可支配费用(元)"
                  prop="actualDisposableProjectExpenseDis"
                  ref="actualDisposableProjectExpenseDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.actualDisposableProjectExpenseDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('actualDisposableProjectExpenseDis')"
                    @focus="focusAmount('actualDisposableProjectExpenseDis')"
                    @blur="blurAmount('actualDisposableProjectExpenseDis')"
                    v-model="projectPerformanceDisDataResponse.actualDisposableProjectExpenseDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc3">商务类</div>
                <el-form-item
                  label="总商务绩效(元)"
                  prop="sumBusinessPerformanceDis"
                  ref="sumBusinessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.sumBusinessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('sumBusinessPerformanceDis')"
                    @focus="focusAmount('sumBusinessPerformanceDis')"
                    @blur="blurAmount('sumBusinessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.sumBusinessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务费(元)"
                  prop="basicBusinessFeeDis"
                  ref="basicBusinessFeeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.basicBusinessFeeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('basicBusinessFeeDis')"
                    @focus="focusAmount('basicBusinessFeeDis')"
                    @blur="blurAmount('basicBusinessFeeDis')"
                    v-model="projectPerformanceDisDataResponse.basicBusinessFeeDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="基本商务绩效(元)"
                  prop="basicBusinessPerformanceDis"
                  ref="basicBusinessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.basicBusinessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('basicBusinessPerformanceDis')"
                    @focus="focusAmount('basicBusinessPerformanceDis')"
                    @blur="blurAmount('basicBusinessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.basicBusinessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="商务部运作费用(元)"
                  prop="businessOperationFeeDis"
                  ref="businessOperationFeeDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.businessOperationFeeDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('businessOperationFeeDis')"
                    @focus="focusAmount('businessOperationFeeDis')"
                    @blur="blurAmount('businessOperationFeeDis')"
                    v-model="projectPerformanceDisDataResponse.businessOperationFeeDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc2">成本/绩效/利润类</div>
                <el-form-item
                  label="生产成本(元)"
                  prop="productionActualCostDis"
                  ref="productionActualCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productionActualCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productionActualCostDis')"
                    @focus="focusAmount('productionActualCostDis')"
                    @blur="blurAmount('productionActualCostDis')"
                    v-model="projectPerformanceDisDataResponse.productionActualCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="部署维护人工成本(元)"
                  prop="deploymentMaintenanceCostDis"
                  ref="deploymentMaintenanceCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.deploymentMaintenanceCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('deploymentMaintenanceCostDis')"
                    @focus="focusAmount('deploymentMaintenanceCostDis')"
                    @blur="blurAmount('deploymentMaintenanceCostDis')"
                    v-model="projectPerformanceDisDataResponse.deploymentMaintenanceCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="项目利润(元)"
                  prop="projectPerformanceDis"
                  ref="projectPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.projectPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('projectPerformanceDis')"
                    @focus="focusAmount('projectPerformanceDis')"
                    @blur="blurAmount('projectPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.projectPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="利润商务绩效(元)"
                  prop="businessPerformanceDis"
                  ref="businessPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.businessPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('businessPerformanceDis')"
                    @focus="focusAmount('businessPerformanceDis')"
                    @blur="blurAmount('businessPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.businessPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="生产绩效(元)"
                  prop="productionPerformanceDis"
                  ref="productionPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productionPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productionPerformanceDis')"
                    @focus="focusAmount('productionPerformanceDis')"
                    @blur="blurAmount('productionPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.productionPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="产品绩效(元)"
                  prop="productPerformanceDis"
                  ref="productPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.productPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('productPerformanceDis')"
                    @focus="focusAmount('productPerformanceDis')"
                    @blur="blurAmount('productPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.productPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="定制改造生产绩效(元)"
                  prop="costUpgradingPerformanceDis"
                  ref="costUpgradingPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.costUpgradingPerformanceDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('costUpgradingPerformanceDis')"
                    @focus="focusAmount('costUpgradingPerformanceDis')"
                    @blur="blurAmount('costUpgradingPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.costUpgradingPerformanceDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="部署维护生产绩效(元)"
                  prop="deploymentMaintenanceCostPerformanceDis"
                  ref="deploymentMaintenanceCostPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.deploymentMaintenanceCostPerformanceDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('deploymentMaintenanceCostPerformanceDis')"
                    @focus="focusAmount('deploymentMaintenanceCostPerformanceDis')"
                    @blur="blurAmount('deploymentMaintenanceCostPerformanceDis')"
                    v-model="
                      projectPerformanceDisDataResponse.deploymentMaintenanceCostPerformanceDis
                    "
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="公司利润(元)"
                  prop="corporateProfitDis"
                  ref="corporateProfitDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.corporateProfitDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('corporateProfitDis')"
                    @focus="focusAmount('corporateProfitDis')"
                    @blur="blurAmount('corporateProfitDis')"
                    v-model="projectPerformanceDisDataResponse.corporateProfitDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <div class="title_text bgc1">部门/外协类</div>
                <el-form-item
                  label="部门管理总绩效(元)"
                  prop="implementationManagementPerformanceDis"
                  ref="implementationManagementPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.implementationManagementPerformanceDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('implementationManagementPerformanceDis')"
                    @focus="focusAmount('implementationManagementPerformanceDis')"
                    @blur="blurAmount('implementationManagementPerformanceDis')"
                    v-model="
                      projectPerformanceDisDataResponse.implementationManagementPerformanceDis
                    "
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协工作监督绩效(元)"
                  prop="outSourceSupervisionPerformanceDis"
                  ref="outSourceSupervisionPerformanceDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.outSourceSupervisionPerformanceDis ==
                      1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('outSourceSupervisionPerformanceDis')"
                    @focus="focusAmount('outSourceSupervisionPerformanceDis')"
                    @blur="blurAmount('outSourceSupervisionPerformanceDis')"
                    v-model="projectPerformanceDisDataResponse.outSourceSupervisionPerformanceDis"
                  ></el-input>
                  <!-- <span style="color:red;">比例取值为2%~5%之间</span> -->
                </el-form-item>
                <!-- </el-descriptions-item > -->

                <!-- <el-descriptions-item label=""> -->
                <el-form-item
                  label="外协费用(元)"
                  prop="outsourceCostDis"
                  ref="outsourceCostDis"
                  style="width: 24%; display: inline-block"
                >
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="
                      projectPerformanceIsAlreadyChangeRequest.outsourceCostDis == 1
                        ? 'bd_input color'
                        : 'bd_input'
                    "
                    @input="inputAmount('outsourceCostDis')"
                    @focus="focusAmount('outsourceCostDis')"
                    @blur="blurAmount('outsourceCostDis')"
                    v-model="projectPerformanceDisDataResponse.outsourceCostDis"
                  ></el-input>
                </el-form-item>
                <!-- </el-descriptions-item> -->
              </template>
              <!-- </el-descriptions> -->
            </el-form>
          </el-card>
        </div></el-col
      >
    </el-row>

    <el-row style="margin-top: 15px">
      <div
        class="tableTOP"
        style="
          background-color: #f5e6d1;
          color: black;
          line-height: 35px;
          font-weight: 900;
          font-size: 18px;
          padding: 10px;
        "
      >
        <div>商务绩效分配</div>
        <div class="fr" v-if="disabledNum != 1">
          <el-button type="success" plain @click="lookOverFn">查看绩效占比</el-button>
        </div>
      </div>

      <el-col :span="12">
        <el-card>
          <el-table
            :data="projectPerformanceDataResponse.businessPerformanceRatioList"
            border
            style="width: 100%"
          >
            <el-table-column type="index" align="center" label="序号" width="70"> </el-table-column>
            <el-table-column prop="staffName" width="140" align="center" label="姓名">
            </el-table-column>
            <el-table-column prop="performanceRatio" align="center" label="绩效比例">
            </el-table-column>
            <el-table-column prop="staffBusinessPerformance" align="center" label="商务绩效">
              <template slot-scope="scope">
                <el-input
                  disabled
                  class="bd_input"
                  v-model.number="scope.row.staffBusinessPerformance"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <el-table
            :data="projectPerformanceDisDataResponse.businessPerformanceRatioList"
            border
            style="width: 100%"
          >
            <el-table-column type="index" align="center" label="序号" width="70"> </el-table-column>
            <el-table-column prop="staffName" width="140" align="center" label="姓名">
            </el-table-column>
            <el-table-column prop="performanceRatio" align="center" label="绩效比例">
            </el-table-column>
            <el-table-column prop="staffBusinessPerformance" align="center" label="商务绩效">
              <template slot-scope="scope">
                <div>
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="scope.row.isAlreadyChange == 1 ? 'bd_input color' : 'bd_input'"
                    @input="inputFn(scope.row, 1)"
                    @blur="blurFn(scope.row, 1)"
                    @focus="focusFn(scope.row, 1)"
                    v-model="scope.row.staffBusinessPerformance"
                  ></el-input>
                </div>
                <template v-if="disabledNum != 1">
                  <div v-show="BusinessIsDText" style="color: red; font-siza: 16px">
                    当前商务绩效分配大于商务总绩效
                  </div>
                  <div v-show="BusinessIssXText" style="color: red; font-siza: 16px">
                    当前商务绩效分配小于商务总绩效
                  </div>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-card></el-col
      >
    </el-row>
    <el-row style="margin-top: 15px">
      <div
        class="tableTOP"
        style="
          background-color: #c7f7e5;
          color: black;
          line-height: 35px;
          font-weight: 900;
          font-size: 18px;
          padding: 10px;
        "
      >
        <span> 部门绩效分配 </span>
      </div>

      <el-col :span="12">
        <el-card>
          <el-table
            :data="projectPerformanceDataResponse.deptPerformanceList"
            border
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
            <el-table-column prop="deptName" align="center" label="部门名称"> </el-table-column>
            <el-table-column prop="taskRatio" align="center" width="140" label="项目任务占比(%)">
              <template slot-scope="scope">
                <el-input class="bd_input" disabled v-model="scope.row.taskRatio"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="deptPerformance" align="center" label="部门绩效">
              <template slot-scope="scope">
                <el-input class="bd_input" disabled v-model="scope.row.deptPerformance"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <el-table
            :data="projectPerformanceDisDataResponse.deptPerformanceList"
            border
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
            <el-table-column prop="deptName" align="center" label="部门名称"> </el-table-column>
            <el-table-column prop="taskRatio" align="center" width="140" label="项目任务占比(%)">
              <template slot-scope="scope">
                <el-input class="bd_input" disabled v-model="scope.row.taskRatio"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="deptPerformance" align="center" label="部门绩效">
              <template slot-scope="scope">
                <div>
                  <el-input
                    :disabled="disabledNum == 1 || disabled"
                    :class="scope.row.isAlreadyChange == 1 ? 'bd_input color' : 'bd_input'"
                    @input="inputFn(scope.row, 2)"
                    @blur="blurFn(scope.row, 2)"
                    @focus="focusFn(scope.row, 2)"
                    v-model="scope.row.deptPerformance"
                  ></el-input>
                </div>
                <template v-if="disabledNum != 1">
                  <div v-show="isDText" style="color: red; font-siza: 16px">
                    当前部门绩效分配大于部门总绩效
                  </div>
                  <div v-show="isXText" style="color: red; font-siza: 16px">
                    当前部门绩效分配小于部门总绩效
                  </div>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog title="商务绩效占比" :visible.sync="dialogVisible" append-to-body width="80%">
      <div class="el-dialog-div">
        <DistriBution
          :options="{ businessManageId: businessManageId }"
          :Disabled="true"
        ></DistriBution>
      </div>
    </el-dialog>

    <el-dialog append-to-body title="更变记录列表" :visible.sync="changeDialog">
      <el-table :data="changeData" border>
        <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
        <!-- <el-table-column
          property="modular"
          align="center"
          label="模块"
        ></el-table-column> -->
        <el-table-column property="createName" align="center" label="姓名"></el-table-column>
        <el-table-column property="createdDate" align="center" label="日期">
          <template slot-scope="scope">
            {{ scope.row.createdDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column property="" align="center" label="操作" width="150">
          <template slot-scope="scope"
            ><el-button size="mini" @click="editFn(scope.row)">查看变更详情</el-button></template
          ></el-table-column
        >
      </el-table>
    </el-dialog>

    <el-dialog title="项目绩效-更变记录" :visible.sync="isShow" append-to-body width="85%">
      <div class="el-dialog-div">
        <div style="margin-bottom: 15px">
          项目名称：
          <el-input
            style="width: 20%; border: 1px solid #ccc; border-radius: 3%; margin-right: 8px"
            disabled
            v-model="projectInformation.projectName"
          ></el-input>
          修改人：
          <el-input
            style="width: 20%; border: 1px solid #ccc; border-radius: 3%; margin-right: 8px"
            disabled
            v-model="NameDate.createName"
          ></el-input>
          修改时间：
          <span
            style="
              width: 20%;
              line-height: 40px;
              padding-left: 10px;
              display: inline-block;
              border: 1px solid #ccc;
              border-radius: 3%;
              margin-right: 8px;
            "
            >{{ NameDate.createdDate | dateFormat }}</span
          >
        </div>
        <ProjectPerformance
          :disabledNum="1"
          :oldDifData="olfInfo"
          :newDifData="newInfo.difData"
          :newIsAlreadyChange="newInfo.projectPerformanceIsAlreadyChange"
          :projectDisRuleOptions="projectDisRuleOptions"
        ></ProjectPerformance>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { getInputValue, delcommafy, numberToCurrencyNo } from '@/util/jsencrypt'
export default {
  name: 'ProjectPerformance',
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    ProjectPerformance: () => import('@/views/project/ProjectPerformance.vue'),
  },
  props: {
    resettingColor: {
      type: Number,
      default: 0,
    },
    disabledNum: {
      type: Number,
      default: 0,
    },
    DEPTPerformanceList: {
      type: Array,
      default: function () {
        return []
      },
    },
    BUSINESSPerformanceRatioList: {
      type: Array,
      default: function () {
        return []
      },
    },
    oldDifData: {
      type: Object,
      default: function () {
        return {}
      },
    },
    newDifData: {
      type: Object,
      default: function () {
        return {}
      },
    },
    newIsAlreadyChange: {
      type: Object,
      default: function () {
        return {}
      },
    },
    projectDisRuleOptions: {
      type: Object,
      default: function () {
        return {}
      },
    },
    USEDProjectDisRuleDisDataResponse: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      disabled: true,
      loading: false,
      changeDialog: false,
      dialogVisible: false,
      isShow: false,
      changeData: [],
      DisposableType: [],
      projectPerformanceDataResponse: {},
      projectPerformanceDisDataResponse: {},
      DataResponse: {
        basicBusinessFee: '',
        basicBusinessPerformance: '',
        businessOperationFee: '',
        companyBasicCharge: '',
        productionActualCost: '',
        productionPerformance: '',
        productionQuotaCost: '',
        projectPerformance: '',
        businessPerformance: '',
        corporateProfit: '',
        implementationManagementPerformance: '',
        projectExpenseSum: '',
        costUpgrading: '',
        deploymentMaintenanceCost: '',
        productPerformance: '',
        costUpgradingPerformance: '',
        deploymentMaintenanceCostPerformance: '',
        productDevelopmentCost: '',
        staffProductionCost: '',
        outSourceSupervisionPerformance: '',
        outsourceCost: '',
        sumBusinessPerformance: '',
        actualDisposableProjectExpense: '',
      },
      DisDataResponse: {
        basicBusinessFeeDis: '',
        basicBusinessPerformanceDis: '',
        businessOperationFeeDis: '',
        companyBasicChargeDis: '',
        productionActualCostDis: '',
        productionPerformanceDis: '',
        productionQuotaCostDis: '',
        projectPerformanceDis: '',
        businessPerformanceDis: '',
        corporateProfitDis: '',
        implementationManagementPerformanceDis: '',
        projectExpenseSumDis: '',
        costUpgradingDis: '',
        deploymentMaintenanceCostDis: '',
        productPerformanceDis: '',
        costUpgradingPerformanceDis: '',
        deploymentMaintenanceCostPerformanceDis: '',
        productDevelopmentCostDis: '',
        staffProductionCostDis: '',
        outSourceSupervisionPerformanceDis: '',
        outsourceCostDis: '',
        sumBusinessPerformanceDis: '',
        actualDisposableProjectExpenseDis: '',
      },
      projectPerformanceIsAlreadyChangeRequest: {
        basicBusinessFeeDis: 0,
        basicBusinessPerformanceDis: 0,
        businessOperationFeeDis: 0,
        companyBasicChargeDis: 0,
        productionActualCostDis: 0,
        productionPerformanceDis: 0,
        productionQuotaCostDis: 0,
        projectPerformanceDis: 0,
        businessPerformanceDis: 0,
        corporateProfitDis: 0,
        implementationManagementPerformanceDis: 0,
        projectExpenseSumDis: 0,
        costUpgradingDis: 0,
        deploymentMaintenanceCostDis: 0,
        productPerformanceDis: 0,
        costUpgradingPerformanceDis: 0,
        deploymentMaintenanceCostPerformanceDis: 0,
        productDevelopmentCostDis: 0,
        staffProductionCostDis: 0,
        outSourceSupervisionPerformanceDis: 0,
        outsourceCostDis: 0,
        sumBusinessPerformanceDis: 0,
        actualDisposableProjectExpenseDis: 0,
      },
      businessManageId: '',
      businessNum: 0,
      deptPerformancNum: 0,
      newInfo: {
        difData: {},
        projectPerformanceIsAlreadyChange: {},
      },
      olfInfo: {},
      NameDate: {},
      sourceType: [],
      sourceDetailedType: [],
      usedProjectDisRuleDisDataResponse: this.USEDProjectDisRuleDisDataResponse || {},
      businessPerformanceRatioList: this.BUSINESSPerformanceRatioList || [],
      deptPerformanceList: this.DEPTPerformanceList || [],
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
    }),
    BusinessIsDText() {
      let show = false
      let num = 0
      this.projectPerformanceDisDataResponse.businessPerformanceRatioList.forEach(v => {
        num += Number(v.staffBusinessPerformance)
      })
      let sumNum = delcommafy(this.projectPerformanceDisDataResponse.sumBusinessPerformanceDis)
      num = Number(num).toFixed(2) //toFixed(2)数据项保留两位小数
      if (sumNum < Number(num)) {
        show = true
      } else {
        show = false
      }
      return show
    },
    BusinessIssXText() {
      let show = false
      let num = 0
      this.projectPerformanceDisDataResponse.businessPerformanceRatioList.forEach(v => {
        num += Number(v.staffBusinessPerformance)
      })
      let sumNum = delcommafy(this.projectPerformanceDisDataResponse.sumBusinessPerformanceDis)
      num = Number(num).toFixed(2) //toFixed(2)数据项保留两位小数
      if (sumNum > Number(num)) {
        show = true
      } else {
        show = false
      }
      return show
    },
    isDText() {
      let show = false
      let num = 0
      this.projectPerformanceDisDataResponse.deptPerformanceList.forEach(v => {
        num += Number(v.deptPerformance)
      })
      let sumNum = delcommafy(
        this.projectPerformanceDisDataResponse.implementationManagementPerformanceDis
      )
      num = Number(num).toFixed(2) //toFixed(2)数据项保留两位小数
      if (sumNum < Number(num)) {
        show = true
      } else {
        show = false
      }
      return show
    },
    isXText() {
      let show = false
      let num = 0
      this.projectPerformanceDisDataResponse.deptPerformanceList.forEach(v => {
        num += Number(v.deptPerformance)
      })
      let sumNum = delcommafy(
        this.projectPerformanceDisDataResponse.implementationManagementPerformanceDis
      )
      num = Number(num).toFixed(2) //toFixed(2)数据项保留两位小数
      if (sumNum > Number(num)) {
        show = true
      } else {
        show = false
      }
      return show
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('PROJECT_TYPE', true)
      .then(res => {
        this.DisposableType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.sourceType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    dateFormat(time3) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time3)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var strDate = Y + M + D + h + m + s
      return strDate
    },
  },
  watch: {
    'projectDisRuleOptions.projectInformation.projectSource': {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (projectSource) {
        if (projectSource) {
          this.$api.dict
            .listSysDictData(projectSource, true)
            .then(res => {
              this.sourceDetailedType = res.data
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },
    disabledNum: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (disabledNum) {
        if (disabledNum == 0) {
          if (this.permission(['PROJECT_PERFORMANCE_RULES_EDIT'])) {
            this.disabled = false
          } else {
            this.disabled = true
          }
        } else {
          this.disabled = true
        }
      },
    },
    resettingColor: {
      // immediate: true, //初始化立即执行
      // deep: true, //对象深度监测
      handler: function (resettingColor) {
        if (resettingColor) {
          for (const key in this.projectPerformanceIsAlreadyChangeRequest) {
            this.projectPerformanceIsAlreadyChangeRequest[key] = 0
          }
        }
      },
    },
    oldDifData: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (oldDifData) {
        if (this.disabledNum == 1) {
          this.projectPerformanceDataResponse = oldDifData
        }
      },
    },
    newDifData: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newDifData) {
        if (this.disabledNum == 1) {
          this.projectPerformanceDisDataResponse = newDifData
        }
      },
    },
    newIsAlreadyChange: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newIsAlreadyChange) {
        if (newIsAlreadyChange) {
          if (this.disabledNum == 1) {
            this.projectPerformanceIsAlreadyChangeRequest = newIsAlreadyChange
          }
        }
      },
    },
    projectDisRuleOptions: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (projectDisRuleOptions) {
        if (projectDisRuleOptions.id) {
          if (this.disabledNum != 1) {
            if (
              projectDisRuleOptions.projectPerformanceDataResponse == null ||
              projectDisRuleOptions.projectPerformanceDisDataResponse == null
            ) {
              this.projectPerformanceDataResponse = {}
              this.projectPerformanceDisDataResponse = {}
              if (projectDisRuleOptions.id) {
                this.$api.project
                  .projectRuleCalculateRuledata(projectDisRuleOptions.id)
                  .then(res => {
                    this.$store.commit({
                      type: 'project/SET_PROJECTDISRULEOPTIONS',
                      ProjectDisRuleOptions: res.data,
                    })
                    this.projectPerformanceDataResponse = res.data.projectPerformanceDataResponse
                    this.projectPerformanceDisDataResponse =
                      res.data.projectPerformanceDisDataResponse
                    if (res.data.projectPerformanceDisDataResponse) {
                      this.usedProjectDisRuleDisDataResponse =
                        res.data.projectPerformanceDisDataResponse.deepClone()
                    }
                    if (res.data.projectPerformanceDisDataResponse) {
                      this.businessPerformanceRatioList =
                        res.data.projectPerformanceDisDataResponse.businessPerformanceRatioList.deepClone()
                    }
                    if (res.data.projectPerformanceDisDataResponse) {
                      this.deptPerformanceList =
                        res.data.projectPerformanceDisDataResponse.deptPerformanceList.deepClone()
                    }
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }
            } else {
              this.projectPerformanceDataResponse = {}
              this.projectPerformanceDisDataResponse = {}
              this.projectPerformanceDataResponse =
                projectDisRuleOptions.projectPerformanceDataResponse
              this.projectPerformanceDisDataResponse =
                projectDisRuleOptions.projectPerformanceDisDataResponse
            }
          }
        }
      },
    },
    projectPerformanceDataResponse: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal) {
          for (const key in newVal) {
            for (const key1 in this.DataResponse) {
              if (key == key1) {
                if (newVal[key] != null) {
                  newVal[key] = newVal[key].toLocaleString()
                }
              }
            }
          }
        }
      },
    },
    projectPerformanceDisDataResponse: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal) {
          for (const key in newVal) {
            if (newVal[key] == null) {
              newVal[key] = 0
            }
            for (const key1 in this.DisDataResponse) {
              if (key == key1) {
                if (newVal[key] != null) {
                  newVal[key] = newVal[key].toLocaleString()
                }
              }
            }
          }
        }
      },
    },
  },
  methods: {
    inputFn(row, num) {
      if (num == 1) {
        this.projectPerformanceDisDataResponse.businessPerformanceRatioList.forEach(v => {
          if (v.staffId == row.staffId) {
            v.staffBusinessPerformance = String(v.staffBusinessPerformance)
              .replace(/[^\d.]/g, '')
              .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
            this.$forceUpdate()
          }
        })
      } else {
        this.projectPerformanceDisDataResponse.deptPerformanceList.forEach(v => {
          if (v.deptId == row.deptId) {
            v.deptPerformance = String(v.deptPerformance)
              .replace(/[^\d.]/g, '')
              .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
            this.$forceUpdate()
          }
        })
      }
    },
    editFn(row) {
      this.NameDate = row
      this.$api.project
        .projectPerformanceSelectLogDetailById(row.id)
        .then(res => {
          if (res.data) {
            res.data.forEach(v => {
              if (v.logType == 'OLD') {
                if (v.difData == null) {
                  this.olfInfo = {}
                } else {
                  this.olfInfo = v.difData
                }
              } else if (v.logType == 'CHANGE') {
                if (v.difData == null) {
                  this.newInfo.difData = {}
                } else {
                  this.newInfo.difData = v.difData
                  this.newInfo.projectPerformanceIsAlreadyChange =
                    v.projectPerformanceIsAlreadyChange
                }
              }
              {
              }
            })
          }
          this.isShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },
    focusFn(row, num) {
      if (row && num == 1) {
        this.businessPerformanceRatioList.forEach(v => {
          if (v.staffId == row.staffId) {
            this.businessNum = v.staffBusinessPerformance
          }
        })
        return
      }
      if (row && num == 2) {
        this.deptPerformanceList.forEach(v => {
          if (v.deptId == row.deptId) {
            this.deptPerformancNum = v.deptPerformance
          }
        })
      }
    },
    blurFn(row, num) {
      if (row && num == 1) {
        this.projectPerformanceDisDataResponse.businessPerformanceRatioList.forEach(v => {
          if (row.staffId == v.staffId) {
            if (this.businessNum != row.staffBusinessPerformance) {
              v.isAlreadyChange = 1
            } else {
              v.isAlreadyChange = 0
            }
            this.$set(
              this.projectPerformanceDisDataResponse.businessPerformanceRatioList,
              'isAlreadyChange',
              v.isAlreadyChange
            )
          }
        })
        this.$forceUpdate()
        return
      }
      if (row && num == 2) {
        this.projectPerformanceDisDataResponse.deptPerformanceList.forEach(v => {
          if (row.deptId == v.deptId) {
            if (this.deptPerformancNum != row.deptPerformance) {
              v.isAlreadyChange = 1
            } else {
              v.isAlreadyChange = 0
            }
            this.$set(
              this.projectPerformanceDisDataResponse.deptPerformanceList,
              'isAlreadyChange',
              v.isAlreadyChange
            )
          }
        })
        this.$forceUpdate()
      }
    },
    lookOverFn() {
      if (this.projectDisRuleOptions.businessManageId) {
        this.businessManageId = this.projectDisRuleOptions.businessManageId
        this.dialogVisible = true
      }
    },
    countFn() {
      this.loading = true
      this.$api.project
        .projectRuleCalculateRuledata(this.projectDisRuleOptions.id)
        .then(res => {
          this.projectPerformanceDataResponse = res.data.projectPerformanceDataResponse
          if (res.data.projectPerformanceDisDataResponse.projectId) {
            this.projectPerformanceDisDataResponse = res.data.projectPerformanceDisDataResponse
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    changeFn() {
      this.$api.project
        .projectPerformanceLogList({ projectId: this.projectDisRuleOptions.id })
        .then(res => {
          this.changeData = res.data
          this.changeDialog = true
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 值改变触发 "申请金额" 文本框 **/
    inputAmount(e) {
      this.projectPerformanceDisDataResponse[e] = String(this.projectPerformanceDisDataResponse[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },

    /** 获得焦点触发 "申请金额" 文本框 **/
    focusAmount(e) {
      if (
        this.projectPerformanceDisDataResponse[e] != null ||
        this.projectPerformanceDisDataResponse[e] != '' ||
        this.projectPerformanceDisDataResponse[e] != undefined
      ) {
        this.projectPerformanceDisDataResponse[e] = String(
          this.projectPerformanceDisDataResponse[e]
        ).replace(/,/g, '')
      }
      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurAmount(e) {
      if (this.projectPerformanceDisDataResponse[e] != this.usedProjectDisRuleDisDataResponse[e]) {
        this.projectPerformanceIsAlreadyChangeRequest[e] = 1
      } else {
        this.projectPerformanceIsAlreadyChangeRequest[e] = 0
      }

      this.projectPerformanceDisDataResponse[e] = Number(
        this.projectPerformanceDisDataResponse[e]
      ).toLocaleString()
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
/deep/.el-card__body {
  padding: 0px 20px 10px 20px;
}
/deep/.flex {
  display: flex;
  justify-content: space-between;
}
.color {
  background-color: #f5e9057d;
}
.bd_input {
  border: 1px solid #ccc;
  height: 45px;
  border-radius: 5%;
  text-align: center;
}
// 改变input框背景颜色
/deep/.el-input__inner {
  background-color: transparent !important;
  border: 0 !important;
}
/* 利用穿透，设置input边框隐藏 */
>>> .el-input__inner {
  border: 0;
}

.el-dialog-div {
  height: 70vh;
  overflow: auto;
}

.tableTOP {
  display: flex;
  justify-content: space-between;
}
//描述列表的边距
// /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
//   padding: 0px 3px;
//   // padding: 12px 3px;
// }
.el-form {
  display: block;
  .el-form-item {
    margin-bottom: 0;
  }
  .title_text {
    // line-height: 30px;
    padding: 10px;
    border-radius: 2%;
    margin-top: 10px;
    font-weight: 600;
    font-size: 17px;
    color: #685e5e;
  }
  .bgc1 {
    background-color: #c7f7e5;
  }
  .bgc2 {
    background-color: #b1f1ed;
  }
  .bgc3 {
    background-color: #f5e6d1;
  }
  .bgc4 {
    background-color: #bcedc3;
  }
}

/deep/.labelClassName {
  width: 100px;
}
</style>
